/* eslint-disable no-undef*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { CustomButton, CustomOrderedList } from '../MedicalForm/styles';

import QuestionComponent from '../QuestionComponentV2';

import { saveLifestyleData, getLifestyleData } from '../../redux/actions';
import { withTranslation } from 'react-i18next';

class LifestyleForm extends Component {

  componentDidMount() {
    this.props.fetchLifestyleData();
  }

  navigateUser = (e, value) => {
    e.preventDefault();
    const { history } = this.props;
    history.push(value);
  };

  render() {
    const { lifestyleData, addLifestyleData, hraQuestionsAnswersData } = this.props;
    return (
      <form>
        <CustomOrderedList>
          {
            hraQuestionsAnswersData['lifestyle'].map((section, index) => {
              let addedAnswers = '';
              lifestyleData && lifestyleData.length > 0 && lifestyleData.map((data) => {
                if(data.question === section.question_id) {
                  addedAnswers = data.answer
                }
              });
              return (
                <QuestionComponent
                  key={section.question}
                  section={section}
                  addedAnswers={addedAnswers}
                  saveData={addLifestyleData}
                  color={"#C464AC"}
                  questionNo={index+1}
                />
              )
            })
          }
        </CustomOrderedList>
        <CustomButton
          left
          onClick={(e) => this.navigateUser(e, '/wellness-assessment/health')}
        >
          {this.props.t("back")}
        </CustomButton>
        <CustomButton
          onClick={(e) => this.navigateUser(e, '/wellness-assessment/fitness')}
          disabled={_.isUndefined(lifestyleData) || lifestyleData.length<hraQuestionsAnswersData['lifestyle'].length}
        >
          {this.props.t("next")}
        </CustomButton>
      </form>
    )
  }
}

LifestyleForm.propTypes = {
  addLifestyleData: PropTypes.func.isRequired,
  fetchLifestyleData: PropTypes.func.isRequired,
  lifestyleData: PropTypes.array,
  history: PropTypes.object.isRequired,
  hraQuestionsAnswersData: PropTypes.array,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  lifestyleData: state.wellBeingAssessment.lifestyleData,
});

const mapDispatchToProps = (dispatch) => ({
  addLifestyleData: (question, answer, isMultipleAnswers, count) => dispatch(saveLifestyleData(question, answer, isMultipleAnswers, count)),
  fetchLifestyleData: () => dispatch(getLifestyleData())
});

export default connect(mapStateToProps, mapDispatchToProps)((withTranslation())(LifestyleForm));