import styled, { css } from 'styled-components';
import { Modal } from 'react-bootstrap';
import { UserButton } from '../EngagementSection/styles';

const RegularFont = 'Rubik-Regular';

const Link = styled.a`
  cursor: pointer;
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    transform: translate(0, 50vh) translate(0, -50%) !important;
    margin: 0 auto;
    
    @media (max-height: 460px) {
      transform: none !important;
      margin: 30px auto;
    }
  }
`;

const StyledHeader = styled(Modal.Header)`
  padding: 25px;
  .close {
    float: right;
    margin-top: -20px;
    margin-right: -16px;
  }
`;

const StyledHeaderForLightbox = styled(Modal.Header)`
  padding: 25px;
  border-bottom: hidden;
  .close {
    float: right;
    margin-top: -20px;
    margin-right: -16px;
  }
`;

const Button = styled(UserButton)`
  padding: ${({padding}) => padding ? padding : '7px 10px'};
  margin-bottom: 20px !important;
  opacity: ${({disabled}) => disabled && '0.5'};
  cursor: ${({disabled}) => disabled && 'not-allowed !important'};
`;

const Padding = styled.div`
  padding: 10px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  .name {
    margin-left: 10px;
    font-size: 14px;
    letter-spacing: 0px;
    color: ${({color}) => color ? '#000' : 'rgb(153,153,153)'};
    font-family: ${RegularFont};
    text-transform: none;
  }
`;

const CommonContainer = styled.div`
  width: 100%;
  height: ${({fitnessHeight}) => fitnessHeight ? '286px' : '100%'};
  background-color:  ${({bgColor}) => bgColor ? bgColor : 'white'}; 
  padding: ${({padding}) => padding ? padding : '10px'};
  border-top: ${({ borderTop }) => borderTop ? '1px solid #e9e8e8' : ''};
  float: ${({float}) => float ? 'left': ''};
  margin-top: 0px;
  font-size: 12px;
  text-transform: uppercase;
  text-align: ${({textAlign}) => textAlign ? 'center': 'left'};
  @media (max-width: 760px) {
    float: ${({float}) => float ? 'left': ''};
    padding: ${({smallPadding}) => smallPadding && smallPadding };
  }
  .fitnessDeviceHeight {
    padding-top: 10px;
  }
  .highcharts-credits {
    display: none; 
  }
  .border {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center !important;
    padding: 2px;
    .button {
      display: flex;
      > span{
        text-transform: uppercase;
        padding: 3px;
        > div {
          display: inline-block;
        }
      }
      .text {
        margin-left: 10px;
      }
    }
  }

  > div {
    > span {
      > img {
        height: 25px;
        width: 25px;
        margin-right: 10px;
      }
    }
  }
  
  ${({ flex }) => flex && css`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    overflow: auto;
  `}
`;

const Container = styled.div`
  width: 70%;
  margin-left: 15%;
  position: relative;
  .headerName{
    font-size: 18px;
    letter-spacing: 0px;
    color: rgb(51,51,51);
    font-family: ${RegularFont};
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }
  @media (max-width: 720px) {
    width: 100%;
    margin: 0px;
  }
`;
const Message = styled.div`
  text-align: center;
`;

export { StyledHeaderForLightbox, StyledModal, Button, Padding, CommonContainer , Container, StyledHeader, Link, Message };
